const _ = {
  load() {
    const placeFormDataset = document.getElementById('place_form').dataset
    const allActivities = JSON.parse(placeFormDataset.allActivities)
    const placeActivities = JSON.parse(placeFormDataset.placeActivities)

    for (const activity of allActivities) {
      const destroyButton = document.getElementById(`destroy_${activity}_button`)
      const createButton = document.getElementById(`create_${activity}_button`)
      const activityFields = document.getElementById(`${activity}_fields`)

      if (placeActivities.includes(activity)) {
        document.getElementById(`create_${activity}_button`).style.display = 'none'
      } else {
        activityFields.querySelector('.destroy_tag').value = true
        activityFields.style.display = 'none'
        destroyButton.style.display = 'none'
      }

      createButton.addEventListener('click', (e) => {
        const activity = e.target.dataset.activity
        const activityFields = document.getElementById(`${activity}_fields`)

        activityFields.querySelector('.destroy_tag').value = false

        e.target.style.display = 'none'
        activityFields.style.display = 'block'
        document.getElementById(`destroy_${activity}_button`).style.display = 'inline'
      })

      destroyButton.addEventListener('click', (e) => {
        if (!confirm('Are you sure you want to destroy this activity')) {
          return
        }

        const activity = e.currentTarget.dataset.activity
        const activityFields = document.getElementById(`${activity}_fields`)

        activityFields.querySelector('.destroy_tag').value = true

        activityFields.style.display = 'none'
        e.currentTarget.style.display = 'none'
        document.getElementById(`create_${activity}_button`).style.display = 'inline'
      })
    }
  }
}

document.addEventListener('turbolinks:load', () => {
  const dataset = document.body.dataset

  if (dataset.controller === 'places' && ['new', 'edit', 'update'].includes(dataset.action)) {
    _.load()
  }
})
