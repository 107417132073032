// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/pro-regular-svg-icons'
import '../stylesheets/application.scss'

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')

// custom packs
require('packs/prices/tag_price_value_to_be_destroyed')
require('packs/places/tag_cremations_per_year_to_be_destroyed')
require('packs/places/tag_activities_to_be_destroyed')

// Bootstrap
document.addEventListener('turbolinks:load', () => {
  $('[data-toggle="tooltip"').tooltip()
  $('[data-toggle="popover"').popover()
})

// Font Awesome
library.add(far)
dom.watch()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
