const _ = {
  load() {
    const destroys = Array.from(document.getElementsByClassName('destroy_tag'))
    const values = destroys.map(d => d.parentNode.querySelector('input[type=number]'))

    values.forEach((v) => {
      v.addEventListener('blur', (e) => {
        const destroy = e.target.parentNode.querySelector('.destroy_tag')
        destroy.value = !e.target.value
      })
    })
  }
}

document.addEventListener('turbolinks:load', () => {
  const dataset = document.body.dataset

  if (dataset.controller === 'prices' && dataset.action === 'edit') {
    _.load()
  }
})
